<template>
  <div class="app-container">
    <baseTable ref="table" class="search-form non-expand-icon" :dic="dic" :option="option" :resetMergeData="resetMergeData"  :api="getList" :checkAll="true">
      <template #menuLeft="scoped">
        <exportBill
          v-if="checkPermission('externaladmin:financialManagement:userBalance:exportbill')"
          :beforeOpen="getSelectionData"
          :data="selectionData"
        />
      </template>
      <template #updateTime="{ row }"> {{ parseTime(row.updateTime) }} </template>
      <template #menu="{ row }">
        <color-text-btn v-p="['externaladmin:financialManagement:userBalance:changeBalance']" @click="hanldeBalance(row)"
          >余额增减</color-text-btn
        >
        <color-text-btn v-p="['externaladmin:financialManagement:userBalance:history']" uiid="zd-history" @click="handleHistory(row)"
          >历史记录</color-text-btn
        >
      </template>
    </baseTable>
    <balance-dialog
      ref="balanceDialog"
      :sup_this="sup_this"
      :balanceData="balanceData"
      :form.sync="form"
      @updateForm="updateForm"
    />
  </div>
</template>

<script>
import searchHeader from './module/searchHeader.vue'
import exportBill from './module/exportBill'
import { parseTime } from '@/utils'
import { balanceCols as cols, option } from './module/cols'
import BalanceDialog from './module/balanceDialog.vue'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import {userList as getList,balanceRecordexport}from '@/api/financial/userbalance'
import saveAs from 'jszip/vendor/FileSaver.js'

export default {
  name: 'userBalance',
  components: { searchHeader, BalanceDialog, exportBill },
  mixins: [
    baseTable
  ],
  data() {
    return {
      option,
      cols,
      sup_this: this,
      form: {
        currentBalance: null,
        balanceChange: null,
        modifiedBalance: null,
        remark: ''
      },
      balanceData: {},
      resetMergeData:{},
      query: {
        userLevel: '',
        nickName: '',
        accountName: ''
      },
      exportLoading:false,
      billList:[]
    }
  },
  computed:{
    dic() {
      return {
        userLevel: this.$store.getters.memberLevelList
      }
    }
  },
  //缓存页面失活时调用
  deactivated() {
    this.$refs.balanceDialog.balanceVisible = false
  },
  methods: {
    getList,
    parseTime,
    hanldeBalance(data) {
      this.balanceData = data
      this.form.currentBalance = data.currentBalance || 20
      this.form.balanceChange = 0
      this.form.modifiedBalance = data.currentBalance || 20
      this.$refs.balanceDialog.balanceVisible = true
    },
    updateForm(data) {
      const { balanceChange, modifiedBalance } = data
      this.form.balanceChange = balanceChange
      this.form.modifiedBalance = modifiedBalance
    },
    // sortChange({ prop, order }) {
    //   if (prop == 'levelName') {
    //     const state = order == 'ascending' ? true : false
    //     this.mergeData = {
    //       orderItems: [
    //         {
    //           column: 'member_level',
    //           asc: state
    //         }
    //       ]
    //     }
    //     this.init()
    //   } else if (prop == 'accountAmount') {
    //     const state = order == 'ascending' ? true : false
    //     this.mergeData = {
    //       orderItems: [
    //         {
    //           column: 'account_amount',
    //           asc: state
    //         }
    //       ]
    //     }
    //     this.init()
    //   } else if ((prop = 'updateTime')) {
    //     const state = order == 'ascending' ? true : false
    //     this.mergeData = {
    //       orderItems: [
    //         {
    //           column: 'update_time',
    //           asc: state
    //         }
    //       ]
    //     }
    //     this.init()
    //   }
    // },
    handleHistory(data) {
      const { id } = data
      this.$router.push({ path: '/financialManagement/history', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
</style>
